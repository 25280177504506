((w, d) => {
  const meters = d.querySelectorAll('.graph')
  for (const meter of meters) {
    const animation = gsap.fromTo(meter, { '--angle': '0deg' }, { '--angle': `${meter.dataset.value}deg`, duration: 3, ease: "power1.inOut", paused: true })
    const observer = new IntersectionObserver((entries, observer) => {
      for (entry of entries) {
        if (entry.intersectionRatio == 1) {
          animation.play();
          observer.disconnect();
        }
      }
    }, { threshold: 1 })
    observer.observe(meter);
  }

})(window, document)